import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BirdsService } from '../../birds.service';

const MAX = 12;

@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.css']
})

export class LearningComponent implements OnInit {
  SELECTED = 'LEARN';
  data: any = new Object();

  constructor(private birdService: BirdsService, private router: Router) { }

  ngOnInit() {
    // this.fetchBirds();
    this.fetchBirds(1);
  }

  // NEW!!!!!
  fetchBirds(p) {
    this.birdService
    .getBirds(MAX, p)
    .subscribe(res => {
      this.data = res;
    });
  }

}
