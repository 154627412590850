import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LearningComponent} from './components/learning/learning.component';
import {LearningDetailsComponent} from './components/learning-details/learning-details.component';
import {HomepageComponent} from './components/homepage/homepage.component';
import {GameComponent} from './components/game/game.component';


const routes: Routes = [
  { path: 'learning', component: LearningComponent },
  { path: 'game', component: GameComponent },
  { path: 'learning/:bird_id', component: LearningDetailsComponent },
  { path: 'home', component: HomepageComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
