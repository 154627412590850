import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BirdsService {

  constructor(private http: HttpClient) {   }

  getBirds(limit, page) {
    return this.http.get(`/api/bird?limit=${limit}&page=${page}`);
  }

  getBirdById(birdurl) {
    return this.http.get(`/api/bird/${birdurl}`);
  }

}
