import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute } from '@angular/router';

import { BirdsService } from '../../birds.service';

@Component({
  selector: 'app-learning-details',
  templateUrl: './learning-details.component.html',
  styleUrls: ['./learning-details.component.css']
})
export class LearningDetailsComponent implements OnInit {
  SELECTED = 'LEARN';
  msaapDisplayVolumeControls = true;
  // next two fields for debugging
  // randomText = "You can see this"
  // msbapAudioUrl = 'assets/static/audio/Australian-Shelduck/Australian-shelduck_5abf4fa5a13efe0457c357ee_Denoised.wav'; 
  data: any = new Object();
  audio: any = new Audio();
  images: string[] = new Array();

  constructor(private birdService: BirdsService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    const list = [1, 2, 3];
    for (const i of list) {
      this.images.push('/assets/static/bird768/' + this.route.snapshot.paramMap.get('bird_id') + '0' + i + '.jpg');
    }

    this.route.params.subscribe(params => {
      this.birdService.getBirdById(params.bird_id).subscribe(res => {
        this.data = res;
      });
    });

  }

}

